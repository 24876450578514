// 節流, 连续触发事件时，保证一定时间内只调用一次函数。
const throttle = {
    bind: function (el, binding, vnode) {
        let throttleTime = binding.value.throttle || 500;
        let lastTime = 0;
        el.addEventListener('click', () => {
          let nowTime = new Date().getTime();
          if (nowTime - lastTime > throttleTime) {
            binding.value.handler();
            lastTime = nowTime;
          }
        });
    }
};

export { throttle };

// <button v-throttle="{ throttle: 1000, handler: submitForm }">提交</button>
