// 防抖，触发事件后，在指定的时间间隔内，若无进一次触发，则执行函数，如果在这个时间间隔内有新的事件触发，则重新计时。
const debounce = {
  bind: function (el, binding, vnode) {
    let timeout;
    el.addEventListener('click', () => {
      if (!timeout) {
        timeout = setTimeout(() => {
          binding.value();
          timeout = null;
        }, 3000); // 设置延时
      }
    });
  }
};

export { debounce };

// <button v-debounce="submitForm">提交</button>
